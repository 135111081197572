// ChatInterface.js
import React from 'react';

const ChatInterface = ({ chatStarted, chatMessages, message, setMessage, sendMessage }) => (
  <div>
    <h2>Chat Interface</h2>
    <div>
      {chatMessages.map((msg, index) => (
        <p key={index}>{msg}</p>
      ))}
    </div>
    <textarea
      value={message}
      onChange={(e) => setMessage(e.target.value)}
      placeholder="Type your message..."
    />
    <button onClick={sendMessage}>Send Message</button>

    {chatStarted && (
      <p>
        To end the conversation, type "thank you" and the chat will be summarized and updated to your Notion Page.
      </p>
    )}
  </div>
);

export default ChatInterface;
