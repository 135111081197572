import React, { useState } from 'react';
import ChatInterface from './ChatInterface';
import { call_NotionAPI, callOpen_AI_API } from './apiFunctions';

const ChatContainer = ({ user, data }) => {
  const [message, setMessage] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [chatStarted, setChatStarted] = useState(false);
  const [notionPageId, setNotionPageId] = useState(null); // Store the current Notion page ID
  const [isChatGPTThinking, setIsChatGPTThinking] = useState(false);

  const startChat = async () => {
    try {
      if (!data || !data['notion-api'] || !data['page-id']) {
        console.error('Notion API key or Page ID is missing.');
        return;
      }
  
      const notionApiKey = data['notion-api'];
      const pageId = data['page-id'];
      const userName = user.displayName;
  
      if (!pageId || !userName || !notionApiKey) {
        console.error('User name, Notion API key, or Page ID is missing.');
        return;
      }
  
      const result = await call_NotionAPI(user, data, 'createPage');
      console.log(result);
  
      if (result.success) {
        alert('Notion page created successfully!');
        setChatStarted(true);
        setNotionPageId(result.pageId); // Store the current Notion page ID
        setChatMessages([
          `ChatGPT: How do you feel today?`,
          // You can add more initial messages if needed
        ]);
      }
    } catch (error) {
      console.error('Error starting chat:', error);
    }
  };
  
  const sendMessage = async () => {
    const userName = user.displayName;
    const summary_prompt = data['summarize_prompt']
    console.log("USER NAME: ", userName)

    try {
      // Create a formatted string representing the user's message with timestamp
      const userMessage = `${userName} : ${message}`;

      // Check if the user's message is "thank you" to end the chat
      if (message.toLowerCase() === 'thank you') {
        setIsChatGPTThinking(true);

        // Summarize chat history using OpenAI API
        const summaryResponse = await callOpen_AI_API(
          null,
          summary_prompt,
          data,
          setChatMessages,
          chatMessages,
          'summarize'
        );

        // Update chat history to Notion page with the summary
        const notionApiResponse = await call_NotionAPI(user, data, 'addContent', {
          'page-id': data['page-id'],
          'content': summaryResponse, // Use the summarized content
        }, notionPageId);

        // Log for debugging
        console.log('Notion API Response:', notionApiResponse);

        // Reset chat history and end the chat
        setChatMessages([]);
        setChatStarted(false);
        setIsChatGPTThinking(false);

        return;
      }

      setIsChatGPTThinking(true);

      // Call the OpenAI API with the user's message and update chat history
      const openaiResponse = await callOpen_AI_API(
        userMessage,
        data['system_message'],
        data,
        setChatMessages,
        chatMessages,
        'chat'
      );

      // Clear the message input field
      setMessage('');
      setIsChatGPTThinking(false);

      // Log for debugging
      console.log('User Message:', userMessage);
      console.log('OpenAI Response:', openaiResponse);
    } catch (error) {
      console.error('Error sending message:', error);
      setIsChatGPTThinking(false);
    }
  };

  return (
    <div>
      {!chatStarted && (
        <button onClick={startChat}>Start Chat</button>
      )}

      {chatStarted && !isChatGPTThinking && (
        <ChatInterface
          chatStarted={chatStarted}
          chatMessages={chatMessages}
          message={message}
          setMessage={setMessage}
          sendMessage={sendMessage}
        />
      )}

      {isChatGPTThinking && (
        <div className="spinner-container">
          <div className="spinner"></div>
          <p>ChatGPT is thinking...</p>
        </div>
      )}
    </div>
  );
};

export default ChatContainer;