// AuthButton.js
import React from 'react';

const AuthButton = ({ user, signOut, signInWithGoogle }) => (
  <>
    {user ? (
      <>
        <p>Welcome, {user.displayName}!</p>
        <button onClick={signOut}>Sign Out</button>
      </>
    ) : (
      <button onClick={signInWithGoogle}>Sign in with Google</button>
    )}
  </>
);

export default AuthButton;
