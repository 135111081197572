// src/components/apiFunctions.js

const call_NotionAPI = async (user, data, mode = 'createPage', content = null, notionPageId) => {
  try {
    if (!data || !data['notion-api']) {
      console.error('Notion API key is missing.');
      return { success: false };
    }

    const notionApiKey = data['notion-api'];
    const userName = user.displayName;

    if (!userName || !notionApiKey) {
      console.error('User name or Notion API key is missing.');
      return { success: false };
    }

    let apiUrl;
    let requestBody;

    if (mode === 'createPage') {
      if (!data['page-id']) {
        console.error('Page ID is missing for page creation.');
        return { success: false };
      }

      const pageId = data['page-id'];

      apiUrl = 'https://reflectionapi.jinfinite.com.hk/create-page';
      requestBody = {
        pageId,
        userName,
        notionApiKey,
      };
    } else if (mode === 'addContent') {
      if (!content || !notionPageId || !content['content']) {
        console.error('Page ID or content is missing for adding content to a page.');
        return { success: false };
      }

      const pageId = notionPageId;
      const contentToAdd = content['content'];

      apiUrl = 'https://reflectionapi.jinfinite.com.hk/add-content';
      requestBody = {
        pageId,
        userName,
        notionApiKey,
        content: contentToAdd,
      };
    } else {
      console.error('Invalid mode specified.');
      return { success: false };
    }

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    const result = await response.json();
    console.log(result);

    return result;
  } catch (error) {
    console.error('Error calling Notion API:', error);
    return { success: false };
  }
};

// apiFunctions.js

const callOpen_AI_API = async (userResponse, systemPrompt, data, setChatMessages, chatMessages, mode = 'chat') => {
  try {
    const apiKeyFromDB = data ? data["openai-api"] : null;
    const summary_prompt = data['summarize_prompt']
    console.log(summary_prompt)

    if (!apiKeyFromDB) {
      throw new Error('OpenAI API key is missing.');
    }

    const endpoint = 'https://api.openai.com/v1/chat/completions';

    // Get the current time
    const currentTime = new Date().toLocaleTimeString();

    console.log('Chat History:', chatMessages);

    let messages;

    if (mode === 'chat') {
      // Include the current time in the system prompt
      const systemPromptWithTime = `${systemPrompt} The current time is: ${currentTime}. Please use this time contextually when needed, like the user staying up too late or waking up too early. The previous chat history is as follows: ${chatMessages.join(' ')} Please always try to be as human as possible and answer under context`;

      messages = [
        { role: 'system', content: systemPromptWithTime },
        { role: 'user', content: userResponse },
      ];
    } else if (mode === 'summarize') {
      // Provide a specific prompt for summarization
      const summarizationPrompt = `${systemPrompt}: ${chatMessages.join(' ')}`;

      messages = [
        { role: 'system', content: summarizationPrompt },
      ];
    } else {
      console.error('Invalid mode specified.');
      return 'Error processing response';
    }

    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKeyFromDB}`,
      },
      body: JSON.stringify({
        model: 'gpt-4-1106-preview',
        messages: messages,
        temperature: 0.7
      }),
    });

    const result = await response.json();

    // Format the AI's response with timestamp
    const aiResponse = `ChatGPT: ${result.choices[0].message.content}`;

    // Update the chat history using the setChatMessages function
    setChatMessages(prevMessages => [...prevMessages, userResponse, aiResponse]);

    return result.choices[0].message.content;
  } catch (error) {
    console.error('Error calling OpenAI API:', error);
    return 'Error processing response';
  }
};

export { call_NotionAPI, callOpen_AI_API };
