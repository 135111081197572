// src/firebase.js

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database'; // Add this line to import the database module

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCfnVX3-zH8VFYdmns5xpDSYfyxBhOn0ew",
  authDomain: "gpt-reflection-bot-notion.firebaseapp.com",
  projectId: "gpt-reflection-bot-notion",
  storageBucket: "gpt-reflection-bot-notion.appspot.com",
  messagingSenderId: "887885486354",
  appId: "1:887885486354:web:e808f092982ab73ee27bcb",
  databaseURL: "https://gpt-reflection-bot-notion-default-rtdb.asia-southeast1.firebasedatabase.app" // Add this line with the correct database URL
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
export const db = firebase.database(); // Add this line to export the database module

export default firebase;