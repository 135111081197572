import React, { useState } from 'react';

const SetupWizard = ({ data, proceedButtonClick }) => {
  const [notionApiKey, setNotionApiKey] = useState('');
  const [openaiApiKey, setOpenaiApiKey] = useState('');
  const [pageId, setPageId] = useState('');

  const handleNotionApiKeyChange = (e) => {
    setNotionApiKey(e.target.value);
  };

  const handleOpenaiApiKeyChange = (e) => {
    setOpenaiApiKey(e.target.value);
  };

  const handlePageIdChange = (e) => {
    setPageId(e.target.value);
  };

  return (
    <div>
      <h2>Quick Start Wizard</h2>

      {/* Display missing API information */}
      {!data['notion-api'] && (
        <div>
          <p>
            Notion API is missing. Please follow these steps to obtain the Notion API key:
          </p>
          <ol>
            <li>Click <a href="https://www.notion.so/my-integrations" target="_blank" rel="noopener noreferrer">this link</a> to access your integrations.</li>
            <li>Click the + New integration button.</li>
            <li>Provide a Name for your integration. Optionally, you can also upload an image to use as a Logo.</li>
            <li>Use the dropdown menu to specify which Associated workspace you would like to use this integration with.</li>
            <li>Copy the API key and paste it here.</li>
          </ol>
          <input
            type="text"
            placeholder="secert-xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
            value={notionApiKey}
            onChange={handleNotionApiKeyChange}
          />
        </div>
      )}

      {!data['openai-api'] && (
        <div>
          <p>
            OpenAI API is missing. Please follow these steps to obtain the OpenAI API key:
          </p>
          <ol>
            <li>Go to the OpenAI API platform. Make sure your key supports GPT-4.</li>
            <li>Copy the API key and paste it here.</li>
          </ol>
          <input
            type="text"
            placeholder="sk-xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
            value={openaiApiKey}
            onChange={handleOpenaiApiKeyChange}
          />
        </div>
      )}

      {!data['page-id'] && (
        <div>
          <p>
            Page ID is missing. Please follow these steps to obtain the Page ID:
          </p>
          <ol>
            <li>Go to your Notion page.</li>
            <li>Copy the Page ID from the URL (e.g., https://www.notion.so/your_userid/Self-Reflection-xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx).</li>
            <li>In the case above is the "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx" part.</li>
            <li>Go to the pages settings (Notion page top right...).</li>
            <li>Scroll down and press "Add connections" and select the integrations you just created.</li>
          </ol>
          <input
            type="text"
            placeholder="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
            value={pageId}
            onChange={handlePageIdChange}
          />
        </div>
      )}

      <button onClick={() => proceedButtonClick(notionApiKey, openaiApiKey, pageId)}>Proceed</button>
    </div>
  );
};

export default SetupWizard;
