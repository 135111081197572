import React, { useState, useEffect } from 'react';
import { auth, googleAuthProvider, db } from './firebase';
import AuthButton from './components/AuthButton';
import SetupWizard from './components/SetupWizard';
import ChatContainer from './components/ChatContainer';
import './styles/styles.css';

const App = () => {
  const [user, setUser] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserData = async (uid) => {
    const dataRef = db.ref(`users/${uid}`);
    try {
      const snapshot = await dataRef.once('value');
      return snapshot.val();
    } catch (error) {
      console.error('Error fetching data:', error);
      return null;
    }
  };

  const signInWithGoogle = async () => {
    try {
      await auth.signInWithPopup(googleAuthProvider);
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  const signOut = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const proceedButtonClick = async (notionApi, openaiApi, pageId) => {
    // Check if any of the values is missing
    if (!notionApi || !openaiApi || !pageId) {
      alert('Please provide all the required information.');
      return;
    }
  
    const userRef = db.ref(`users/${user.uid}`);
    try {
      await userRef.set({
        'notion-api': notionApi,
        'openai-api': openaiApi,
        'page-id': pageId,
        'system_message': "Welcome to our reflective space. This is a moment for introspection. Please a helpful and human guide to the reflection process.",
        'summarize_prompt': "Let's distill our discussion into a concise reflection. Make it easy to read with point form and interesting formatting. Include related emojis to make it engaging. Provide a preview sentence for a quick check and more details below."
      });
  
      const updatedData = await fetchUserData(user.uid);
  
      console.log('Data after storage attempt:', updatedData);
  
      setData({ ...data, ...updatedData });
    } catch (error) {
      console.error('Error storing data:', error);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        setUser(authUser);
        const userData = await fetchUserData(authUser.uid);

        if (!userData) {
          const initialData = {
            'notion-api': '',
            'openai-api': '',
            'page-id': '',
            'system_message': "Welcome to our reflective space. This is a moment for introspection. Please a helpful and human guide to the reflection process.",
            'summarize_prompt': "Let's distill our discussion into a concise reflection. Make it easy to read with point form and interesting formatting. Include related emojis to make it engaging. Provide a preview sentence for a quick check and more details below."
          };

          const userRef = db.ref(`users/${authUser.uid}`);
          try {
            await userRef.set(initialData);
            setData(initialData);
          } catch (error) {
            console.error('Error creating initial data:', error);
          }
        } else {
          setData(userData);
        }
      } else {
        setUser(null);
        setData(null);
      }

      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    console.log('Data values:', data);

    if (data) {
      console.log('Checking Notion API:', data['notion-api'] === '');
      console.log('Checking OpenAI API:', data['openai-api'] === '');
      console.log('Checking Page ID:', data['page-id'] === '');

      // Display setup wizard if any of the values is missing
      if (!data['notion-api'] || !data['openai-api'] || !data['page-id']) {
        console.log('Displaying setup wizard');
      }
    }
  }, [data]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h1>ChatGPT Reflection Robot with Notion integration</h1>
      <p>Created by Jimmy WU @ 2024</p>
      <AuthButton user={user} signOut={signOut} signInWithGoogle={signInWithGoogle} />

      {user ? (
        data && (data['notion-api'] === '' || data['openai-api'] === '' || data['page-id'] === '') ? (
          <SetupWizard data={data} proceedButtonClick={proceedButtonClick} />
        ) : (
          <ChatContainer user={user} data={data} />
        )
      ) : null}
    </div>
  );
};

export default App;
